import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

import pic12 from '../assets/images/pic12.jpg'
import pic13 from '../assets/images/pic13.jpg'
import pic14 from '../assets/images/pic14.jpg'

const Tuition = (props) => (
  <Layout>
    <Helmet>
      <title>The Coleman School of Dance</title>
      <meta name="Coleman Dance Tuition" content="Tuition and Policy" />
    </Helmet>

    <BannerLanding header="Tuition and Policy" />

    <div id="main">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h2>2022-2023 Coleman School of Dance </h2>
            <h3>Start Date: Monday, September 12th</h3>

            <h3>Our annual tuition is paid by semester or by quarter</h3>
          </header>
          <p>
            <p>
              We accept credit card payments through Zelle, Venmo and PayPal
              <br />
              If paying by check, please make checks payable to The Coleman
              School of Dance
            </p>
          </p>
          <dl>
            <dt>One Class Per Week</dt>
            <dd>$20 per class</dd>
            <dd>$740 per year</dd>
            <dd>Two payments of $370 or four payments of $190</dd>
          </dl>
          <dl>
            <dt>Two Classes Per Week</dt>
            <dd>$18 per class</dd>
            <dd>$1,332 per year</dd>
            <dd>Two payments of $666 or four payments of $338</dd>
          </dl>
          <dl>
            <dt>Three Classes per week</dt>
            <dd>$16 per class</dd>
            <dd>$1,776 per year</dd>
            <dd>Two payments of $888 or four payments of $444</dd>
          </dl>
          <dl>
            <dt>Unlimited Option</dt>
            <dd>$14 per class</dd>
            <dd>$2,072 per year</dd>
            <dd>Two payments of $1,036 or four payments of $518</dd>
          </dl>
          <dl>
            <dt>Extra Individual Classes</dt>
            <dd>$25 per class</dd>
          </dl>
          <dl>
            <dt>September 19</dt>
            <dd>First Semester Payment Due</dd>
            <dd>First Quarter Payment Due</dd>
          </dl>
          <dl>
            <dt>November 1</dt>
            <dd> Second quarter payment due</dd>
          </dl>
          <dl>
            <dt>February 1</dt>

            <dd>Second semester payment due</dd>
            <dd>Third quarter payment due</dd>
          </dl>
          <dl>
            <dt>April 1</dt>
            <dd>Fourth quarter payment due</dd>
          </dl>

          <p>
            <b>Tuition is non-refundable</b>
            <br />
            Students are allowed one make-up class per quarter in case they have
            to miss.
          </p>
        </div>
      </section>
      <section id="two" className="spotlights">
        <section>
          <Link to="/generic" className="image">
            <img src={pic13} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Fees</h3>
              </header>
              <p>
                Registration Fee: $35.00 per Student
                <br />
                <br />
                Recital Fee-- To cover theatre costs-- $50.00 per Student
                <br />
                <br />
                Returned check fee: $25.00
                <br />
                <br />
                Late payment fee: $25.00
              </p>
            </div>
          </div>
        </section>
        <section>
          <Link to="/generic" className="image">
            <img src={pic14} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Supplies</h3>
              </header>
              <dl>
                <dt>Ballet:</dt>
                <dd>Age 3-8: Leotard, tights, pink ballet slippers</dd>
                <dd>
                  Ages 8 and up: Leotard, tights, split-sole ballet slippers
                </dd>
              </dl>
              <dl>
                <dt>Modern Dance:</dt>
                <dd>
                  Black leotard, black shorts, black convertible tights, pink
                  convertible tights
                </dd>
              </dl>
            </div>
          </div>
        </section>
        <section>
          <Link to="/generic" className="image">
            <img src={pic12} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Studio Policy</h3>
              </header>
              <dl>
                <dt>Tardiness:</dt>
                <dd>
                  In order for everyone to get the most out of their class, we
                  will be starting on time. Arriving after your class has
                  started is disruptive to the teacher and the other students.
                </dd>
                <br />
                <dt>Dress Code:</dt>
                <dd>
                  Students are expected to come to class in proper attire and
                  footwear. Hair must be up, out of the face.
                </dd>
              </dl>
            </div>
          </div>
        </section>
      </section>
    </div>
  </Layout>
)

export default Tuition
